

import * as CryptoJS from 'crypto-ts'

const key = CryptoJS.enc.Utf8.parse("secret_key_12345");

export default {
    // 加密函數
    Encrypt(word:string) {
        const srcs = CryptoJS.enc.Utf8.parse(word);
        const encrypted = CryptoJS.AES.encrypt(srcs, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.PKCS7
        });
        return encrypted.toString();
    },
    // 解密函數
    Decrypt(word:string) {
        const decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.PKCS7
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
}
