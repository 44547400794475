<template>
  <div>
    <van-button @click="test" round block type="primary">测试接口</van-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addErrorLog } from "@/api/web";

export default defineComponent({
  setup() {
    const test = () => {
      addErrorLog({ msg: "记录错误测试" });
    };
    return {
      test,
    };
  },
});
</script>
