<template>
  <div v-if="isShowErrorPage" class="wrapper">
    <van-icon class="fail-icon" name="fail" />
    <p>{{ errorMsg }}</p>
  </div>
</template>

<script lang="ts">
import { getUserByUnionId } from "@/api/web";

import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const isShowErrorPage = ref(false);
    const errorMsg = ref("");
    const EDU_URL = "https://fz.edu.zjfazhi.com/h5/#/pages/bridge";
    const store = useStore();

    /**
     * 通过unionId查询用户信息
     */
    const queryUserInfo = async () => {
      const userInfoRes = await getUserByUnionId({
        unionId: store.state.unionId,
      });
      if (userInfoRes && (userInfoRes as any).resp_code === 0 && (userInfoRes as any).datas) {
        window.location.replace(`${EDU_URL}?params=${(userInfoRes as any).datas || ''}`);
        return;
      }
      errorMsg.value = (userInfoRes as any).resp_msg;
      isShowErrorPage.value = true;
    };

    onMounted(() => {
      queryUserInfo();
    });

    return { isShowErrorPage, errorMsg };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .fail-icon {
    position: relative;
    top: -10%;
    font-size: 72px;
    background: #ccc;
    padding: 32px;
    border-radius: 50%;
    color: #fff;
  }
  p {
    position: relative;
    top: -10%;
    font-size: 22px;
    color: #ccc;
  }
}
</style>
