
import { reactive, defineComponent, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getVerificationCode, login, passwordLogin } from "@/api/web";
import { Toast } from "vant";
import jm from '../utils/jiami';
import { authLogin } from '@/utils/auth';
import $store from '@/store';
import $router from '@/router';

const handleRouteByUserType = (type: string, fromPath?: string) => {
  let tab = type.includes("WP")  ? "task" : "resource";
  $store.commit("updateName", tab);
  $store.commit("updateUserType", type);
  sessionStorage.removeItem('isLogout');

  // 若跳转的路径和当前路径相同，刷新页面，避免路由相同，组件不更新问题
  if (fromPath === `/${tab}`) {
    $store.dispatch('reloadPage');
    return;
  }
  $router.replace(`/${tab}`);
};

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    // 退出登录过来的直接进入登录页
    if (sessionStorage.getItem('isLogout')) {
      next();
      return;
    }
    authLogin(to)
      .then(() => {
        handleRouteByUserType($store.state.type, from.path);
      })
      .catch((err) => {
        // true表示需要执行渲染
        if (err && typeof err === 'boolean') {
          next();
        }
      });
  },

  setup() {
    const tabsList = reactive([
      { label: "验证码登录", value: "code" },
      { label: "密码登录", value: "password" },
    ]);

    const curTab = ref("code");

    const clickTab = (v: string) => {
      curTab.value = v;
    };

    const state = reactive({
      deviceId: "", // 13761293356
      validCode: "", // 8888
      validPassword: "",
    });

    const isSubmit = reactive({
      flag: true,
      codeText: "发送验证码",
    });

    const formRef = ref();
    const vcodeRef = ref();

    const handleSendVcode = async () => {
      const form = unref(formRef);
      if (!form) return;
      if (!isSubmit.flag) {
        return;
      }
      try {
        await form.validate("mobile");
      } catch (err) {
        Toast({
          type: "fail",
          message: (err as Error).message,
        });
        return;
      }

      const res = await getVerificationCode(state.deviceId);
      // 发送成功后调用倒计时
      if (res && (res as any).resp_code === 0) {
        vcodeRef.value.focus();
        Toast({
          type: "success",
          message: "发送成功",
        });
        intervalEvent();
      }
    };

    const intervalEvent = () => {
      isSubmit.flag = false;
      let count = 60;
      let time = setInterval(() => {
        count--;
        isSubmit.codeText = count + "s";
        if (count == 1) {
          clearInterval(time);
          isSubmit.flag = true;
          isSubmit.codeText = "发送验证码";
        }
      }, 1000);
    };

    // 判断是否有宣传员权限处理默认跳转路由
    const store = useStore();
    const router = useRouter();

    const onSubmit = async () => {
      if (curTab.value == "code") {
        const res = await login({
          ...state,
          openId: store.state.openId,
          unionId: store.state.unionId,
        });
        if ((res as any).resp_code === 0) {
          const { access_token, token_type, userType, userId, expires_in, refresh_token } = (res as any).datas;
          store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
          store.commit("updateUserType", userType);
          store.commit("updateUserId", userId);
          handleRouteByUserType(userType);
        }
      } else if (curTab.value == "password") {
        const params = {
          name: state.deviceId,
          pass: jm.Encrypt(state.validPassword),
          type:'2'
        }
        const res = await passwordLogin({
          ...params,
          openId: store.state.openId,
          unionId: store.state.unionId,
        });
        if ((res as any).resp_code === 0) {
          const { access_token, token_type, userType, userId, expires_in, refresh_token } = (res as any).datas;
          store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
          store.commit("updateUserType", userType);
          store.commit("updateUserId", userId);
          handleRouteByUserType(userType);
        }
      }
    };
    const retrievePassword = () => {
      router.push("retrieve");
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validMobile = (value: string) => {
      return !!value.match(/^1[3-9]\d{9}$/);
    };

    let btnName = ref("eye-o");
    let passwordType = ref("password");

    const changeBtnType = () => {
      if (btnName.value === "eye-o") {
        btnName.value = "closed-eye";
        passwordType.value = "";
      } else if (btnName.value === "closed-eye") {
        btnName.value = "eye-o";
        passwordType.value = "password";
      }
    };

    return {
      tabsList,
      curTab,
      clickTab,
      state,
      isSubmit,
      formRef,
      vcodeRef,
      handleSendVcode,
      intervalEvent,
      onSubmit,
      retrievePassword,
      validFailed,
      validMobile,
      btnName,
      passwordType,
      changeBtnType,
    };
  }
});
