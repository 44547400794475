
import { getUserByUnionId } from "@/api/web";

import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const isShowErrorPage = ref(false);
    const errorMsg = ref("");
    const EDU_URL = "https://fz.edu.zjfazhi.com/h5/#/pages/bridge";
    const store = useStore();

    /**
     * 通过unionId查询用户信息
     */
    const queryUserInfo = async () => {
      const userInfoRes = await getUserByUnionId({
        unionId: store.state.unionId,
      });
      if (userInfoRes && (userInfoRes as any).resp_code === 0 && (userInfoRes as any).datas) {
        window.location.replace(`${EDU_URL}?params=${(userInfoRes as any).datas || ''}`);
        return;
      }
      errorMsg.value = (userInfoRes as any).resp_msg;
      isShowErrorPage.value = true;
    };

    onMounted(() => {
      queryUserInfo();
    });

    return { isShowErrorPage, errorMsg };
  },
});
